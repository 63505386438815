<template>
<div class="v-expand-panel">
  <div class="v-expand-panel__target" @click="active = !active" :class="{active}">
    <div class="v-expand-panel__label" v-if="label">{{ label }}</div>
    <ArrowBtmSvg class="v-expand-panel__icon" />
  </div>
  <SlideUpDown v-model="active">
    <div class="v-expand-panel__content">
      <slot />
    </div>
  </SlideUpDown>
</div>
</template>

<script>
import ArrowBtmSvg from "@/components/icons/ArrowBtmSvg";
import SlideUpDown from 'vue3-slide-up-down'
import {ref} from "vue";
export default {
  name: "v-expand-panel",
  props: {
    label: {
      type: String,
      default: ''
    }
  },
  components: {
    SlideUpDown,
    ArrowBtmSvg
  },
  setup() {
    const active = ref(false)

    return {
      active
    }
  }
}
</script>

<style lang="scss" scoped>
.v-expand-panel {
  margin-bottom: 10px;
  &__target {
    max-width: 357px;
    padding: 16px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    border-radius: 8px;
    transition: .2s ease;
    border: 1px solid transparent;
    &:hover {
      border: 1px solid #1B7DCF;
    }
    &.active {
      .v-expand-panel__icon {
        transform: rotate(180deg);
      }
    }
  }
  &__icon {
    width: 28px;
    height: 28px;
    transition: .25s ease;
    margin-left: 15px;
  }
  &__label {
    font-weight: 500;
    font-size: 16px;
  }
  &__content {
    padding-top: 25px;
  }
}
</style>