import { createApp } from 'vue'
import App from './App.vue'
import store from './router'
import "@/assets/scss/top.scss"
let app = createApp(App)

// Modules
app.use(store)

// Components
import VExpandPanel from '@/components/ui/v-expand-panel'

app.component('v-expand-panel', VExpandPanel)

app.config.devtools = true
app.mount('#app')
