<template>
  <div class="app-content">
    <app-bar class="app-content__header" v-model="activeNav" />
    <div class="app-content__wrap">
      <navigation class="app-content__nav" v-model="activeNav" />
      <main class="app-content__content">
        <router-view />
      </main>
    </div>
  </div>
</template>

<script>
import navigation from "@/components/layout/navigation";
import appBar from "@/components/layout/appBar";

export default {
  name: 'App',
  components: {
    navigation,
    appBar
  },
  data() {
    return {
      activeNav: false
    }
  }
}
</script>

<style lang="scss">
.app-content {
  overflow: hidden;
  &__nav {
    width: 30%;
    @include below($desktopSml) {
      width: 100%;
    }
  }
  &__wrap {
    display: flex;
    height: calc(100vh - 80px);
    @include below($tablet) {
      height: calc(100vh - 60px);
    }
  }
  &__content {
    padding: 53px 43px 90px;
    overflow-y: auto;
    height: 100%;
    width: 70%;
    @include below($desktopSml) {
      padding: 30px 30px 60px;
      width: 100%;
    }
    @include below($mobileSml) {
      padding: 20px 12px 40px;
    }
  }
}
</style>
