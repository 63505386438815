<template>
<div class="app-bar">
  <router-link to="/" class="app-bar__link">
    <LogoSvg />
  </router-link>

  <div class="app-bar__burger" @click="$emit('update:modelValue', true)">
    <span></span>
    <span></span>
    <span></span>
  </div>
</div>
</template>

<script>
import LogoSvg from "@/components/icons/LogoSvg";
export default {
  name: "AppBar",
  components: {
    LogoSvg
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.app-bar {
  width: 100%;
  height: 80px;
  border-bottom: 1px solid #DADADA;
  padding: 10px 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #0D1426;
  @include below($tablet) {
    height: 60px;
    padding: 5px 30px;
  }
  @include below($mobileSml) {
    padding: 5px 12px;
  }
  &__burger {
    display: none;
    width: 30px;
    height: 35px;
    position: relative;
    span {
      display: block;
      height: 2px;
      background-color: white;
      width: 100%;
      border-radius: 5px;
      &:nth-child(2) {
        margin: 4px 0;
      }
    }
    @include below($desktopSml) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
  &__logo {
  }
}
</style>